@font-face {
  font-family: Ringside;
  src:
    local(RingsideCondensed-Bold),
    url(../fonts/RingsideCondensed-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: Ringside-Med;
  src:
    local(RingsideRegular-Med),
    url(../fonts/RingsideRegular-Medium.otf) format('opentype'); /* was RingsideRegular-Med but the filename is this */
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: RingsideCompressed;
  src:
    local(RingsideCompressed-Bold),
    url(../fonts/RingsideCompressed-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: RingsideCompressed;
  src:
    local(RingsideCompressed-Black),
    url(../fonts/RingsideCompressed-Black.otf) format('opentype');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: RingsideRegular;
  src:
    local(RingsideRegular-Book),
    url(../fonts/RingsideRegular-Book.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
/*
@font-face {
  font-family: RingsideRegular;
  src: local(RingsideRegular-Bold), url(../fonts/RingsideRegular-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
*/
@font-face {
  font-family: RingsideWide;
  src:
    local(RingsideWide-Bold),
    url(../fonts/RingsideWide-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: RingsideWide-Med;
  src:
    local(RingsideWide-Med),
    url(../fonts/RingsideWide-Medium.otf) format('opentype'); /* was RingsideWide-Med but the filename is this */
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: RingsideWide-Semi;
  src:
    local(RingsideWide-Semi),
    url(../fonts/RingsideWide-Semibold.otf) format('opentype'); /* was RingsideWide-Semi but the filename is this */
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: RingsideWide-Ultra;
  src:
    local(RingsideWide-Ultra),
    url(../fonts/RingsideWide-Ultra.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: SFProDisplay;
  src:
    local(SFProDisplay-Regular),
    url(../fonts/SF-Pro-Display-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

/*
@font-face {
  font-family: SFProText;
  src: local(SFProText-Regular), url(../fonts/SFProText-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
*/
@import '_typography.css';
@import '_variables.css';
@import 'navfont/mml-nav.css';
@import '@top/player-web-lite/dist/player-web-lite.css';
@import 'bitmovinplayer-ui-override.css';

@media (max-height: 1080px) {
  @-ms-viewport {
    height: 1080px;
  }

  @-webkit-viewport {
    height: 1080px;
  }
}

@media screen and (max-height: 720px) {
  body {
    zoom: calc(1280 / 1920);
  }
}

body {
  background: var(--color_lvl_-5);
  margin: 0;
  outline: none;
}

@keyframes live-indicator-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes minimap-left-to-right {
  from {
    left: 174px;
  }

  to {
    left: calc(100% - 300px - 60px);
  }
}

@keyframes minimap-right-to-left {
  from {
    left: calc(100% - 300px - 60px);
  }

  to {
    left: 174px;
  }
}

@keyframes minimap-show-on-left {
  from {
    bottom: -162px;
    left: 174px;
  }

  to {
    bottom: 60px;
    left: 174px;
  }
}

@keyframes minimap-hide-on-left {
  from {
    bottom: 60px;
    left: 174px;
  }

  to {
    bottom: -162px;
    left: 174px;
  }
}

@keyframes minimap-show-on-right {
  from {
    bottom: -162px;
    left: calc(100% - 300px - 60px);
  }

  to {
    bottom: 60px;
    left: calc(100% - 300px - 60px);
  }
}

@keyframes minimap-hide-on-right {
  from {
    bottom: 60px;
    left: calc(100% - 300px - 60px);
  }

  to {
    bottom: -162px;
    left: calc(100% - 300px - 60px);
  }
}

@keyframes wiggle {
  0% {
    left: 0;
  }

  20% {
    left: -8px;
  }

  40% {
    left: 8px;
  }

  60% {
    left: -4px;
  }

  80% {
    left: 4px;
  }

  100% {
    left: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.nav-selected {
  border: 0;
}

/* Add style to the focused elements */
:focus {
  outline: none;
}

/* Hide scroll bar on xbox */
body::-webkit-scrollbar {
  display: none;
}
