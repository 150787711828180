:root {
  --color_lvl_0: #8f909b;
  --color_lvl_1: #a8a9b3;
  --color_lvl_-1: #767783;
  --color_lvl_2: #c1c2ca;
  --color_lvl_-2: #5d5f6b;
  --color_lvl_-3: #464750;
  --color_lvl_3: #dfdfe7;
  --color_lvl_-4: #23232a;
  --color_lvl_4: #f4f4fa;
  --color_lvl_-5: #17171d;
  --color_lvl_5: #fdfdfd;
  --color_primaryaccent_DKMD: #009cde;
  --color_primaryaccent_LTMD: #009cde;
  --color_secondaryaccent: #6cd2ff;
  --color_live_ltmd: #e41e0c;
  --color_live_dkmd: #f43625;
  --color_bcg_accent2_orange: #f08f0d;
  --color_bcg_accent1_blue_LTMD: #005288;
  --color_black: #000000;
  --color_bcg_accent1_blue_DKMD: #0084db;
  /* should probably be in the styleguide */
  --color_bcg_correct: #00a566;
  --color_bcg_incorrect: var(--color_live_dkmd);
  --color_white: #ffffff;
  --color_lvl_-5_0: rgba(23, 23, 29, 0);
  --color_lvl_-5_30: rgba(23, 23, 29, 0.3);
  --color_lvl_-5_70: rgba(23, 23, 29, 0.7);
  --color_primaryaccent_DKMD_50: rgba(0, 156, 222, 0.5);
  --color_black_0: rgba(0, 0, 0, 0);
  --color_black_10: rgba(0, 0, 0, 0.1);
  --color_black_80: rgba(0, 0, 0, 0.8);
  --color_black_50: rgba(0, 0, 0, 0.5);
  --color_white_0: rgba(255, 255, 255, 0);
  --color_white_90: rgba(255, 255, 255, 0.9);
}
