.ConnH1LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 105px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 15px;
  text-align: center;
  color: var(--color_lvl_5);
}

.ConnH2LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 90px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  color: var(--color_lvl_5);
}

.ConnH11LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 62px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.ConnH12LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.ConnH13LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.ConnH5LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_5);
}

.ConnBody-4LVL-5 {
  font-family: 'Ringside', sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 0.56;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.ConnBody-1 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fefefe;
}

.ConnBody-3LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color_lvl_5);
}

.ConnH9LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.ConnH3LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: right;
  color: var(--color_lvl_5);
}

.ConnButtonPrimary-Blue {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.65px;
  color: var(--color_primaryaccent_DKMD);
}

.ConnBulletsLVL-5 {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.66px;
  text-align: center;
  color: var(--color_lvl_5);
}

.ConnCaption-2Live {
  font-family: 'RingsideWide', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #f22613;
}

.ConnCaption-2LVL-5 {
  font-family: 'RingsideWide', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: var(--color_lvl_5);
}

.ConnTab-BarLVL-5 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: var(--color_lvl_5);
}

.ConnTab-BarLVL-3 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: right;
  color: var(--color_lvl_3);
}

.ConnTab-BarLVL-0 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: var(--color_lvl_0);
}

.ConnCaption-3LVL-5 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: var(--color_lvl_5);
}

.ConnCaption-3LVL-3 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: var(--color_lvl_3);
}

.ConnH8LVL-5 {
  font-family: 'RingsideWide-Ultra', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.ConnButtonPrimaryDKMD {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 22.3px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.39px;
  text-align: center;
  color: #ffffff;
}

.ConnButtonLVL-5 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  color: var(--color_lvl_5);
}

.ConnSubtitle-6LVL-5 {
  font-family: 'RingsideWide', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color_lvl_5);
}

.ConnH6LVL-0 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--color_lvl_0);
}

.ConnH6LVL--5 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.ConnSubtitle-3LVL-5 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #fdfefd;
}

.ConnSubtitle-3LVL--5 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: var(--color_lvl_-5);
}

.OverlineLVL-0 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: var(--color_lvl_0);
}

.LVPH1LVL-5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 160px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -2px;
  color: var(--color_lvl_5);
}

.LVPH1LVL--5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 160px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -2px;
  color: var(--color_lvl_-5);
}

.ConnH1LVL-5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 105px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: -1px;
  color: var(--color_lvl_5);
}

.ConnH1LVL--5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 105px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: -1px;
  color: var(--color_lvl_-5);
}

.SVPH1LVL-5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 80px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: -1.25px;
  color: var(--color_lvl_5);
}

.SVPH1LVL--5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 80px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: -1.25px;
  color: var(--color_black);
}

.ConnH4-focusedLvl5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 55px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_5);
}

.ConnH4-focusedLvl4L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 55px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_4);
}

.ConnH4-focusedLvl0L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 55px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_0);
}

.ConnH4Lvl5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.5px;
  color: var(--color_lvl_5);
}

.ConnH4Lvl4L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.5px;
  color: var(--color_lvl_4);
}

.ConnH4Lvl0L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.5px;
  color: var(--color_lvl_0);
}

.ConnH-Connected-1LVL-5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.ConnH-Connected-1LVL--5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.LVPH4LVL-5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_5);
}

.LVPH4LVL-4L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_4);
}

.LVPH4LVL-3L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_3);
}

.LVPH4LVL-2L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_2);
}

.LVPH4LVL-1L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_1);
}

.LVPH4LVL-0L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_0);
}

.LVPH4LVL--5L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-5);
}

.LVPH4LVL--4L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-4);
}

.LVPH4LVL--3L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-3);
}

.LVPH4LVL--2L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-2);
}

.LVPH4LVL--1L {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-1);
}

.ConnBody-1LVL-5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.ConnBody-1LVL--5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.SVPH4LVL-5-or-MVPH5LVL-5-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_5);
}

.SVPH4LVL-4-or-MVPH5LVL-4-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_4);
}

.SVPH4LVL-3-or-MVPH5LVL-3-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_3);
}

.SVPH4LVL-2-or-MVPH5LVL-2-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_2);
}

.SVPH4LVL-1-or-MVPH5LVL-1-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_1);
}

.SVPH4LVL-0-or-MVPH5LVL-0-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_0);
}

.SVPH4LVL--5-or-MVPH5LVL--5-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-5);
}

.SVPH4LVL--4-or-MVPH5LVL--4-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-4);
}

.SVPH4LVL--3-or-MVPH5LVL--3-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-3);
}

.SVPH4LVL--2-or-MVPH5LVL--2-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-2);
}

.SVPH4LVL--1-or-MVPH5LVL--1-ask-Steven {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-1);
}

.ConnSubtitle-2Lvl5L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 3px;
  color: var(--color_lvl_5);
}

.ConnSubtitle-2Lvl4L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 3px;
  color: var(--color_lvl_4);
}

.ConnSubtitle-2Lvl0L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 3px;
  color: var(--color_lvl_0);
}

.ConnHeadlineLVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 28px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.79;
  letter-spacing: -0.5px;
  text-align: center;
  color: var(--color_lvl_5);
}

.H3LVL-0 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: -0.5px;
  color: var(--color_lvl_0);
}

.H3LVL--5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: -0.5px;
  color: var(--color_lvl_-5);
}

.ConnBulletsLVL-5L {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.65px;
  color: var(--color_lvl_5);
}

.ConnBulletsLVL--5L {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.65px;
  color: var(--color_lvl_-5);
}

.ConnSubtitle-1LVL-5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_5);
}

.ConnSubtitle-1LVL-4L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_4);
}

.ConnSubtitle-1LVL-3L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_3);
}

.ConnSubtitle-1LVL-2L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_2);
}

.ConnSubtitle-1LVL-1L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_1);
}

.ConnSubtitle-1LVL-0L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_0);
}

.ConnSubtitle-1LVL--5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_-5);
}

.ConnSubtitle-1LVL--4L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_-4);
}

.ConnSubtitle-1LVL--3L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_-3);
}

.ConnSubtitle-1LVL--2L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_-2);
}

.ConnSubtitle-1LVL--1L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.8px;
  color: var(--color_lvl_-1);
}

.SVPH5LVL-5-or-LVPH9LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.SVPH5LVL--5-or-LVPH9LVL--5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.LVPBody-1LVL-5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.LVPBody-1LVL--5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.ConnButtonPrimaryLVL-5 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_5);
}

.ConnButtonPrimaryLVL-4 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_4);
}

.ConnButtonPrimaryLVL-3 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_3);
}

.ConnButtonPrimaryLVL-2 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_2);
}

.ConnButtonPrimaryLVL-1 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_1);
}

.ConnButtonPrimaryLVL-0 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_0);
}

.ConnButtonPrimaryLVL--5 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_-5);
}

.ConnButtonPrimaryLVL--4 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_-4);
}

.ConnButtonPrimaryLVL--3 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_-3);
}

.ConnButtonPrimaryLVL--2 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.25px;
  text-align: center;
  color: var(--color_lvl_-2);
}

.ConnButtonPrimaryLVL--1 {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.2px;
  text-align: center;
  color: var(--color_lvl_-1);
}

.ConnCaption-1LiveL {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--color_live_dkmd);
}

.ConnCaption-1LVL-5L {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--color_lvl_5);
}

.ConnCaption-1LVL-4L {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--color_lvl_4);
}

.ConnCaption-1LVL-3L {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--color_lvl_3);
}

.ConnCaption-1LVL-2L {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--color_lvl_2);
}

.ConnCaption-1LVL-1L {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--color_lvl_1);
}

.ConnCaption-1LVL-0L {
  font-family: 'Ringside-Med', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--color_lvl_0);
}

.Body-2LVL-5 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.Body-2LVL-4 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_4);
}

.Body-2LVL-3 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_3);
}

.Body-2LVL-2 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_2);
}

.Body-2LVL-1 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_1);
}

.Body-2LVL-0 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_0);
}

.Body-2LVL--5 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.Body-2LVL--4 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_-4);
}

.Body-2LVL--3 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_-3);
}

.Body-2LVL--2 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color_lvl_-2);
}

.Body-2LVL--1 {
  font-family: 'Ringside', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_-1);
}

.SVPBody-1-or-LVPCaption-5-ask-StevenLVL-5 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.SVPBody-1-or-LVPCaption-5-ask-StevenLVL-2 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--color_lvl_2);
}

.SVPBody-1-or-LVPCaption-5-ask-StevenLVL--5 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.SVPBody-1-or-LVPCaption-5-ask-StevenLVL--2 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--color_lvl_-2);
}

.SVPBody-1-or-LVPCaption-5-ask-StevenLVL--1 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--color_lvl_-1);
}

.LVPCaption-2LVL-5 {
  font-family: 'RingsideWide', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.47px;
  color: var(--color_lvl_5);
}

.LVPCaption-2LVL--5 {
  font-family: 'RingsideWide', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.5px;
  color: var(--color_black);
}

.LVPCaption-2AccentLive {
  font-family: 'RingsideWide', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.47px;
  color: var(--color_live_ltmd);
}

.LVPCaption-1Live_LTMDL {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_live_dkmd);
}

.LVPCaption-1Live_DKMDL {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_live_ltmd);
}

.LVPCaption-1LVL-4L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_lvl_4);
}

.LVPCaption-1LVL-3L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_lvl_3);
}

.LVPCaption-1LVL-2L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_lvl_-2);
}

.LVPCaption-1LVL-1L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_lvl_1);
}

.LVPCaption-1LVL-0L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_lvl_0);
}

.LVPCaption-1LVL--5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.LVPCaption-1LVL--4L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_lvl_-4);
}

.LVPCaption-1LVL--1L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_lvl_-1);
}

.LVPCaption-1BCGBCG-Blue-LTMDL {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_bcg_accent1_blue_LTMD);
}

.LVPCaption-1BCGBCG-Blue-DKMDL {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color_bcg_accent1_blue_DKMD);
}

.ConnH6LVL-5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.LVPSubtitle-1LVL-5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_5);
}

.LVPSubtitle-1LVL-4L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_4);
}

.LVPSubtitle-1LVL-3L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_3);
}

.LVPSubtitle-1LVL-2L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_2);
}

.LVPSubtitle-1LVL-1L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_1);
}

.LVPSubtitle-1LVL-0L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_0);
}

.LVPSubtitle-1LVL--5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-5);
}

.LVPSubtitle-1LVL--4L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-4);
}

.LVPSubtitle-1LVL--3L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-3);
}

.LVPSubtitle-1LVL--2L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-2);
}

.LVPSubtitle-1LVL--1L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-1);
}

.ConnSubtitle-3Lvl5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--color_lvl_5);
}

.ConnSubtitle-3Lvl4L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--color_lvl_4);
}

.ConnSubtitle-3Lvl0L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--color_lvl_0);
}

.ConnButtonLVL-5L {
  font-family: 'RingsideWide-Semi', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--color_lvl_5);
}

.SVPCaption-2LVL-5L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_5);
}

.SVPCaption-2LVL-4L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_4);
}

.SVPCaption-2LVL-3L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_3);
}

.SVPCaption-2LVL-2L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_2);
}

.SVPCaption-2LVL-1L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_1);
}

.SVPCaption-2LVL-0L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_0);
}

.SVPCaption-2LVL--5L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-5);
}

.SVPCaption-2LVL--4L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-4);
}

.SVPCaption-2LVL--3L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-3);
}

.SVPCaption-2LVL--2L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-2);
}

.SVPCaption-2LVL--1L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-1);
}

.SVPCaption-1Live_LTMDL {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_live_ltmd);
}

.SVPCaption-1Live_DKMDL {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_live_dkmd);
}

.SVPCaption-1LVL-5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_5);
}

.SVPCaption-1LVL-4L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_4);
}

.SVPCaption-1LVL-3L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_3);
}

.SVPCaption-1LVL-2L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_2);
}

.SVPCaption-1LVL-1L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_1);
}

.SVPCaption-1LVL-0L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_0);
}

.SVPCaption-1LVL--5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-5);
}

.SVPCaption-1LVL--4L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-4);
}

.SVPCaption-1LVL--3L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-3);
}

.SVPCaption-1LVL--2L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-2);
}

.SVPCaption-1LVL--1L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_lvl_-1);
}

.SVPCaption-1BCGBCG-Blue-LTMDL {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_bcg_accent1_blue_LTMD);
}

.SVPCaption-1BCGBCG-Blue-DKMDL {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: var(--color_bcg_accent1_blue_DKMD);
}

.SVPSubtitle-1LVL-5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_5);
}

.SVPSubtitle-1LVL-4L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_4);
}

.SVPSubtitle-1LVL-3L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_3);
}

.SVPSubtitle-1LVL-2L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_2);
}

.SVPSubtitle-1LVL-1L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_1);
}

.SVPSubtitle-1LVL-0L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_0);
}

.SVPSubtitle-1LVL--5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_-5);
}

.SVPSubtitle-1LVL--4L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_-4);
}

.SVPSubtitle-1LVL--3L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_-3);
}

.SVPSubtitle-1LVL--2L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_-2);
}

.SVPSubtitle-1LVL--1L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--color_lvl_-1);
}

.LVPSubtitle-2LVL-5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_5);
}

.LVPSubtitle-2LVL-4L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_4);
}

.LVPSubtitle-2LVL-3L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_3);
}

.LVPSubtitle-2LVL-2L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_2);
}

.LVPSubtitle-2LVL-1L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_1);
}

.LVPSubtitle-2LVL-0L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_0);
}

.LVPSubtitle-2LVL--5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-5);
}

.LVPSubtitle-2LVL--4L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-4);
}

.LVPSubtitle-2LVL--3L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-3);
}

.LVPSubtitle-2LVL--2L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-2);
}

.LVPSubtitle-2LVL--1L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.2px;
  color: var(--color_lvl_-1);
}

.Status-BarRight-Black {
  font-family: 'SFProText', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #030303;
}

.ConnOverlineLVL-5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.ConnOverlineLVL-0L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: var(--color_lvl_0);
}

.ConnOverlineLVL--5L {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.SVPSubtitle-6LiveL {
  font-family: 'RingsideWide', sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--color_live_ltmd);
}

.SVPSubtitle-6LVL--5L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.SVPSubtitle-6LVL--0L {
  font-family: 'RingsideWide', sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLive {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_live_ltmd);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL-5 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_5);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL-4 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_4);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL-3 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_3);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL-2 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_2);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL-1 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_1);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL-0 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_0);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL--5 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_-5);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL--4 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_-4);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL--3 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_-3);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL--2 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_-2);
}

.Subtitle-3-or-SVPSubtitle-2-ask-StevenLVL--1 {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: var(--color_lvl_-1);
}

.OverlineLVL-5 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.OverlineLVL---5 {
  font-family: 'RingsideRegular', sans-serif;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: var(--color_lvl_-5);
}

.SVPSubtitle-4LVL--5L {
  font-family: 'RingsideWide-Med', sans-serif;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.8px;
  color: var(--color_lvl_-5);
}

.ConnH10LVL-5 {
  font-family: 'RingsideCompressed', sans-serif;
  font-size: 76px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color_lvl_5);
}

.StorybookReminder {
  background-color: var(--color_bcg_incorrect);
  color: var(--color_lvl_5);
  text-align: center;
}

.StorybookTitle {
  background-color: var(--color_bcg_accent2_orange);
  color: var(--color_lvl_5);
  text-align: center;
}
