/*
  there is a new api under IPlayer.updateCaptionSettings (https://topdocs.ngtv.io/api/lite/player/web/interfaces/CCSettings.html)
  that's theoretically supposed to be able to replace the caption styling we have here... but it didn't seem to work
  when I tried it.
 */
div#wm-mml-pwa-video-container span.wbdui-ui-subtitle-label {
  background-color: #000000bf;
  font-family: 'Courier New', Courier, monospace;
  font-size: 32px !important;
  left: 0 !important;
  letter-spacing: initial !important;
}

/* Disable captions during doublebox */
div#wm-mml-pwa-video-parent.isDoubleBoxPrerollActive
  div#wm-mml-pwa-video-container
  .wbdui-ui-uicontainer.wbdui-flexbox
  .wbdui-ui-subtitle-overlay
  .wbdui-container-wrapper {
  display: none;
}

/* Position slates/icons in the center of the screen (play button/buffering icon/etc)*/
div#wm-mml-pwa-video-container
  .wbdui-ui-uicontainer.wbdui-flexbox:not(.wbdui-player-state-playing):not(.wbdui-player-state-paused):not(
    .wbdui-player-state-finished
  )
  > .wbdui-container-wrapper,
div#wm-mml-pwa-video-container
  .wbdui-ui-uicontainer.wbdui-flexbox.wbdui-player-state-playing.wbdui-buffering
  > .wbdui-container-wrapper,
div#wm-mml-pwa-video-parent:not(.media-state-playing):not(.media-state-paused):not(.media-state-finished):not(
    .media-state-pending.ad-state-playing
  )
  div#wm-mml-pwa-video-container
  .wbdui-ui-uicontainer
  > .wbdui-container-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

/* Animate buffering icon */
div#wm-mml-pwa-video-container
  .wbdui-ui-uicontainer.wbdui-flexbox:not(.wbdui-player-state-playing):not(.wbdui-player-state-paused):not(
    .wbdui-player-state-finished
  )
  > .wbdui-container-wrapper:before,
div#wm-mml-pwa-video-container
  .wbdui-ui-uicontainer.wbdui-flexbox.wbdui-player-state-playing.wbdui-buffering
  > .wbdui-container-wrapper:before,
div#wm-mml-pwa-video-parent:not(.media-state-playing):not(.media-state-paused):not(.media-state-finished):not(
    .media-state-pending.ad-state-playing
  )
  div#wm-mml-pwa-video-container
  .wbdui-ui-uicontainer
  > .wbdui-container-wrapper:before {
  animation: spin 0.6s linear infinite;
  border: solid #ffffff1a;
  border-radius: 50%;
  border-top: solid #ccc;
  border-width: 6.24px;
  content: '';
  display: block;
  height: 78px;
  width: 78px;
}
