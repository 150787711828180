div#wm-mml-pwa-video-container span.bmpui-ui-subtitle-label {
  background-color: #000000bf;
  font-family: 'Courier New', Courier, monospace;
  font-size: 24px !important;
  left: 0 !important;
  letter-spacing: initial !important;
}

div#wm-mml-pwa-video-container
  .bmpui-ui-uicontainer.bmpui-flexbox:not(.bmpui-player-state-playing):not(.bmpui-player-state-paused):not(.bmpui-player-state-finished)
  > .bmpui-container-wrapper,
div#wm-mml-pwa-video-container
  .bmpui-ui-uicontainer.bmpui-flexbox.bmpui-player-state-playing.bmpui-buffering
  > .bmpui-container-wrapper,
div#wm-mml-pwa-video-parent:not(.media-state-playing):not(.media-state-paused):not(.media-state-finished):not(.media-state-pending.ad-state-playing)
  div#wm-mml-pwa-video-container
  .bmpui-ui-uicontainer
  > .bmpui-container-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

div#wm-mml-pwa-video-container
  .bmpui-ui-uicontainer.bmpui-flexbox:not(.bmpui-player-state-playing):not(.bmpui-player-state-paused):not(.bmpui-player-state-finished)
  > .bmpui-container-wrapper:before,
div#wm-mml-pwa-video-container
  .bmpui-ui-uicontainer.bmpui-flexbox.bmpui-player-state-playing.bmpui-buffering
  > .bmpui-container-wrapper:before,
div#wm-mml-pwa-video-parent:not(.media-state-playing):not(.media-state-paused):not(.media-state-finished):not(.media-state-pending.ad-state-playing)
  div#wm-mml-pwa-video-container
  .bmpui-ui-uicontainer
  > .bmpui-container-wrapper:before {
  animation: spin 0.6s linear infinite;
  border: solid #ffffff1a;
  border-radius: 50%;
  border-top: solid #ccc;
  border-width: 6.24px;
  content: '';
  display: block;
  height: 78px;
  width: 78px;
}
